/** MY STUFF **/
* {
  outline: none;
  text-align: inherit;
}

html {
  background-color: white;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  min-height: 100vh;
}

a {
  color: inherit;
}

/** CAN REMOVE WHEN REACT-MD IS REPLACED **/
p,
.md-body-1 {
  /* line-height: inherit !important; */
  line-height: inherit;
}

/*
body {
  line-height: inherit !important;
} */

h5,
.md-subheading-1,
h4,
.md-subheading-2,
p,
.md-body-1,
h6,
.md-body-2,
caption,
.md-caption {
  margin-bottom: 0;
}

h1,
.md-display-1,
.md-display-2,
.md-display-3,
.md-display-4,
h2,
.md-headline,
h3,
.md-title {
  margin-bottom: 0;
}

h1,
.md-display-1 {
  line-height: inherit !important;
}
