@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700|Roboto");

/* CSS RESET */
/* html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
} */

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

/* body { */
/* line-height: 1; */
/* } */

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*body { font-family: 'Roboto', sans-serif; }*/
h1,
h2,
h3,
h4,
h5 {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 300 !important;
}

h1 {
  /* padding: 40px 0; */
  font-weight: 300 !important;
}

h3 {
  padding: 10px 0;
  font-weight: 500 !important;
}

h5 {
  padding: 10px 0;
  text-transform: uppercase;
  font-size: 10pt !important;
  font-weight: 500 !important;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
}

.cdmirror .CodeMirror {
  height: 250px;
  margin: 20px 0 35px;
  border: 1px solid #ccc;
  border-radius: 3px;
  text-align: left;
}

.cdmirrorHtml .CodeMirror {
  height: 350px;
  margin: 20px 0 35px;
  border: 1px solid #ccc;
  border-radius: 3px;
  text-align: left;
}

.colored-tab a {
  color: white;
}

.transparent a,
.md-tile-content a {
  color: #333;
}

div[role="checkbox"] {
  display: inline;
  position: relative;
  top: 6px;
}

.md-expansion-panel-list {
  width: 100%;
}

.lazyload-wrapper {
  height: 100%;
}
