@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700|Roboto);
.mce-content-body {
  text-align: inherit;	
}	

.mce-content-body ul {	
  list-style-type: disc;
  padding-left: 1.0rem;
  margin: 0;
}	

.mce-content-body ol {	
  padding-left: 1.0rem;
  margin: 0;
}

.mce-content-body ul li {	
    display: list-item;	
    list-style-type: disc;
}

.mce-content-body ol li {	
    display: list-item;	
    list-style-type: decimal;
}

.tox .tox-pop__dialog {
  min-width: 285px !important;
}
.datetime-picker {
  position: relative;
  border: 1px solid #dbdbdb;
  background-color: #fff;
  border-radius: 4px;
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: #4a4a4a;
  width: 250px;
  box-sizing: content-box;
  z-index: 100
}

.datetime-picker .calendar .calendar-nav {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  min-height: 32px;
  padding: 4px
}

.datetime-picker .calendar .calendar-nav button {
  background: none;
  border: 0;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  outline: none;
  cursor: pointer
}

.datetime-picker .calendar .calendar-nav button .fa {
  font-size: 18px
}

.datetime-picker .calendar .calendar-nav button:hover {
  background-color: #f0f0f0
}

.datetime-picker .calendar .calendar-nav .current-date {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 8px;
  cursor: pointer
}

.datetime-picker .calendar .calendar-nav .current-date:hover {
  background-color: #f0f0f0
}

.datetime-picker .calendar .calendar-nav .current-date.disabled {
  cursor: default
}

.datetime-picker .calendar table {
  display: block;
  margin: 4px
}

.datetime-picker .calendar table td,
.datetime-picker .calendar table th {
  padding: 0
}

.datetime-picker .calendar table thead {
  display: block;
  margin: 8px 0 3px
}

.datetime-picker .calendar table thead tr {
  display: flex;
  flex-wrap: wrap
}

.datetime-picker .calendar table thead th {
  color: #ff9a19;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  text-align: center;
  text-transform: uppercase;
  font-size: .8em;
  font-weight: 400
}

.datetime-picker .calendar table tbody {
  display: block
}

.datetime-picker .calendar table tbody tr {
  display: flex;
  flex-wrap: wrap
}

.datetime-picker .calendar table tbody tr td {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  cursor: pointer
}

.datetime-picker .calendar table tbody tr td:hover {
  background-color: #f0f0f0
}

.datetime-picker .calendar table tbody tr td.disabled,
.datetime-picker .calendar table tbody tr td.next,
.datetime-picker .calendar table tbody tr td.prev {
  color: #dedede
}

.datetime-picker .calendar table tbody tr td.disabled:hover,
.datetime-picker .calendar table tbody tr td.next:hover,
.datetime-picker .calendar table tbody tr td.prev:hover {
  color: #c5c5c5
}

.datetime-picker .calendar table tbody tr td.disabled:hover {
  color: #dedede;
  background-color: transparent;
  cursor: not-allowed
}

.datetime-picker .calendar table tbody tr td.now {
  color: #5cc4ef;
  font-weight: 400
}

.datetime-picker .calendar table tbody tr td.selected {
  background-color: #5cc4ef;
  color: #fff;
  font-weight: 400
}

.datetime-picker .calendar table tbody tr td.selected:hover {
  background-color: #53c1ee;
  color: #fff
}

.datetime-picker .calendar table tbody tr td.selected.start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.datetime-picker .calendar table tbody tr td.selected.end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.datetime-picker .calendar table tbody tr td.selected.start.end {
  border-radius: 4px
}

.datetime-picker .calendar table tbody tr td.between {
  background-color: #f5fbfe;
  border-radius: 0
}

.datetime-picker .calendar .calendar-days table tbody tr td {
  width: 14.28571429%
}

.datetime-picker .calendar .calendar-months table tbody tr td {
  width: 33.33333333%;
  height: 40px
}

.datetime-picker .calendar .calendar-years table tbody tr td {
  width: 25%;
  height: 60px
}

.datetime-picker .time {
  border-top: 1px solid #efefef;
  padding: 4px;
  display: flex;
  align-items: center;
  position: relative
}

.datetime-picker .time .show-time {
  display: flex;
  align-items: center;
  flex: 1 1;
  font-size: 14px;
  text-align: center;
  margin: 0 0 0 10px
}

.datetime-picker .time .show-time .text {
  line-height: 1;
  font-size: 19px;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  position: relative;
  z-index: 1
}

.datetime-picker .time .show-time .separater {
  margin: 0 2px 3px;
  line-height: 1
}

.datetime-picker .time .sliders {
  flex: 0 1 153px;
  margin-right: 10px;
  max-width: 153px
}

.datetime-picker .time .sliders .slider-text {
  display: none
}

.datetime-picker .time .sliders .slider {
  display: flex;
  align-items: center;
  font-size: 11px;
  height: 17px;
  background-image: linear-gradient(90deg, #dedede, #dedede);
  background-position: 0 50%;
  background-size: 100% 1px;
  background-repeat: no-repeat
}

.datetime-picker .time .sliders .slider .handle {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 3px;
  cursor: pointer
}

.datetime-picker .time .sliders .slider .handle:hover {
  border-color: #b8b8b8
}

.datetime-picker .time .sliders .slider .handle:active,
.datetime-picker .time .sliders .slider .handle:focus {
  background-color: #5cc4ef;
  border-color: #5cc4ef
}

.datetime-picker .shortcuts-bar {
  border-bottom: 1px solid #efefef;
  padding: 8px
}

.datetime-picker .shortcuts-bar .btn {
  border: 0;
  background: none;
  cursor: pointer;
  border-radius: 2px;
  padding: 2px 4px;
  outline: none
}

.datetime-picker .shortcuts-bar .btn:hover {
  background-color: #f0f0f0
}

.datetime-picker .shortcuts-bar .btn:last-child {
  float: right
}

.datetime-picker .shortcuts-bar .btn:not(:first-child) {
  margin-left: 5px
}

.datetime-range-picker {
  border: 1px solid #dbdbdb;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: content-box;
  z-index: 100
}

.datetime-trigger {
  position: relative
}

.datetime-trigger .datetime-picker {
  position: absolute;
  top: 100%
}

.datetime-range-trigger {
  position: relative
}

.datetime-range-trigger .datetime-range-picker {
  position: absolute;
  top: 100%
}

.datetime-picker-popup,
.datetime-range-picker-popup {
  margin-top: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15)
}

.datetime-picker-popup:before,
.datetime-range-picker-popup:before {
  content: "";
  position: absolute;
  background: #fff;
  border-top: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  width: 10px;
  height: 10px;
  z-index: -1;
  left: 10px;
  top: -6px;
  transform: rotate(315deg)
}
.body-live .wc ul {
  list-style-type: disc;
  padding-left: 1.0rem;
  margin: 0;
}	

.body-live .wc ol {
  margin: 0;	
  padding-left: 1.0rem;
}

.body-live .wc li {
    display: list-item;	
    list-style-type: disc;
}

.body-live .wc ol li {
  list-style-type: decimal;
}

.body-live .wc {
  text-align: left;
}

.wc a {
  color: inherit;
  /* text-decoration: underline; */
}
.vertical-timeline * {
  box-sizing: border-box;
}
.vertical-timeline {
  width: 95%;
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
  padding: 2em 0;
}
.vertical-timeline::after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  /* background: #fff; */
  background: #ddd;
}
.vertical-timeline.vertical-timeline--one-column-right::before {
  right: 18px;
  left: unset;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline.vertical-timeline--two-columns {
    width: 90%;
  }
  .vertical-timeline.vertical-timeline--two-columns:before {
    left: 50%;
    margin-left: -2px;
  }
}
.vertical-timeline-element {
  position: relative;
  margin: 2em 0;
}
.vertical-timeline-element > div {
  min-height: 1px;
}
.vertical-timeline-element:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-element:first-child {
  margin-top: 0;
}
.vertical-timeline-element:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline-element {
    margin: 4em 0;
  }
  .vertical-timeline-element:first-child {
    margin-top: 0;
  }
  .vertical-timeline-element:last-child {
    margin-bottom: 0;
  }
}
.vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.vertical-timeline--one-column-right .vertical-timeline-element-icon {
  right: 0;
  left: unset;
}
.vertical-timeline-element-icon svg {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns .vertical-timeline-element-icon {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
  }
}
.vertical-timeline-element-icon {
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-icon.is-hidden {
  visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-1 0.6s;
  animation: cd-bounce-1 0.6s;
}
@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.vertical-timeline-element-content {
  position: relative;
  margin-left: 60px;
  background: #ddd;
  border-radius: 0.25em;
  padding: 1em;
  box-shadow: 0 3px 0 #ddd;
}
.vertical-timeline--one-column-right .vertical-timeline-element-content {
  margin-right: 60px;
  margin-left: unset;
}
.vertical-timeline-element--no-children .vertical-timeline-element-content {
  background: 0 0;
  box-shadow: none;
}
.vertical-timeline-element-content:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-element-content h2 {
  color: #303e49;
}
.vertical-timeline-element-content .vertical-timeline-element-date,
.vertical-timeline-element-content p {
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 500;
}
.vertical-timeline-element-content .vertical-timeline-element-date {
  display: inline-block;
}
.vertical-timeline-element-content p {
  margin: 1em 0 0;
  line-height: 1.6;
}
.vertical-timeline-element-title {
  margin: 0;
}
.vertical-timeline-element-subtitle {
  margin: 0;
}
.vertical-timeline-element-content .vertical-timeline-element-date {
  float: left;
  padding: 0.8em 0;
  opacity: 0.7;
}
.vertical-timeline-element-content-arrow {
  content: "";
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid #fff;
}
.vertical-timeline--one-column-right .vertical-timeline-element-content-arrow {
  content: "";
  position: absolute;
  top: 16px;
  right: unset;
  left: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-left: 7px solid #fff;
}
.vertical-timeline--one-column-right
  .vertical-timeline-element-content::before {
  left: 100%;
  border-left: 7px solid #fff;
  border-right: unset;
}
.vertical-timeline-element--no-children
  .vertical-timeline-element-content::before {
  display: none;
}
.vertical-timeline-element--no-children
  .vertical-timeline-element-content-arrow {
  display: none;
}
@media only screen and (min-width: 768px) {
  .vertical-timeline-element-content h2 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .vertical-timeline-element-content p {
    font-size: 16px;
    font-size: 1rem;
  }
  .vertical-timeline-element-content .vertical-timeline-element-date {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns .vertical-timeline-element-content {
    margin-left: 0;
    padding: 1.5em;
    width: 44%;
  }
  .vertical-timeline--two-columns .vertical-timeline-element-content-arrow {
    top: 24px;
    left: 100%;
    transform: rotate(180deg);
  }
  .vertical-timeline--two-columns
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    position: absolute;
    width: 100%;
    left: 124%;
    top: 6px;
    font-size: 16px;
    font-size: 1rem;
  }
  .vertical-timeline--two-columns
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content,
  .vertical-timeline--two-columns
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content {
    float: right;
  }
  .vertical-timeline--two-columns
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content-arrow,
  .vertical-timeline--two-columns
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content-arrow {
    top: 24px;
    left: auto;
    right: 100%;
    transform: rotate(0);
  }
  .vertical-timeline--one-column-right
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content-arrow,
  .vertical-timeline--one-column-right
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content-arrow {
    top: 24px;
    left: 100%;
    right: auto;
    transform: rotate(0);
  }
  .vertical-timeline--two-columns
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content
    .vertical-timeline-element-date,
  .vertical-timeline--two-columns
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    left: auto;
    right: 124%;
    text-align: right;
  }
}
.vertical-timeline--animate .vertical-timeline-element-content.is-hidden {
  visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-2 0.6s;
  animation: cd-bounce-2 0.6s;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns.vertical-timeline--animate
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content.bounce-in,
  .vertical-timeline--two-columns.vertical-timeline--animate
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}
@media only screen and (max-width: 1169px) {
  .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}
@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

/** MY STUFF **/
* {
  outline: none;
  text-align: inherit;
}

html {
  background-color: white;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  min-height: 100vh;
}

a {
  color: inherit;
}

/** CAN REMOVE WHEN REACT-MD IS REPLACED **/
p,
.md-body-1 {
  /* line-height: inherit !important; */
  line-height: inherit;
}

/*
body {
  line-height: inherit !important;
} */

h5,
.md-subheading-1,
h4,
.md-subheading-2,
p,
.md-body-1,
h6,
.md-body-2,
caption,
.md-caption {
  margin-bottom: 0;
}

h1,
.md-display-1,
.md-display-2,
.md-display-3,
.md-display-4,
h2,
.md-headline,
h3,
.md-title {
  margin-bottom: 0;
}

h1,
.md-display-1 {
  line-height: inherit !important;
}

/* CSS RESET */
/* html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
} */

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

/* body { */
/* line-height: 1; */
/* } */

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*body { font-family: 'Roboto', sans-serif; }*/
h1,
h2,
h3,
h4,
h5 {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 300 !important;
}

h1 {
  /* padding: 40px 0; */
  font-weight: 300 !important;
}

h3 {
  padding: 10px 0;
  font-weight: 500 !important;
}

h5 {
  padding: 10px 0;
  text-transform: uppercase;
  font-size: 10pt !important;
  font-weight: 500 !important;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
}

.cdmirror .CodeMirror {
  height: 250px;
  margin: 20px 0 35px;
  border: 1px solid #ccc;
  border-radius: 3px;
  text-align: left;
}

.cdmirrorHtml .CodeMirror {
  height: 350px;
  margin: 20px 0 35px;
  border: 1px solid #ccc;
  border-radius: 3px;
  text-align: left;
}

.colored-tab a {
  color: white;
}

.transparent a,
.md-tile-content a {
  color: #333;
}

div[role="checkbox"] {
  display: inline;
  position: relative;
  top: 6px;
}

.md-expansion-panel-list {
  width: 100%;
}

.lazyload-wrapper {
  height: 100%;
}

